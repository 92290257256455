
import { WorkOrderFormEntityModel, WorkOrderFormQueryModel } from '@/entity-model/work-order-form-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/workorderActiform`;

class WorkOrderFormService implements ICRUDQ<WorkOrderFormEntityModel, WorkOrderFormQueryModel> {
    async create(model: WorkOrderFormEntityModel):Promise<WorkOrderFormEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderFormEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderFormEntityModel().toModel(res);
    }

    async update(model: WorkOrderFormEntityModel):Promise<WorkOrderFormEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderFormEntityModel):Promise<WorkOrderFormEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderFormQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;

        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);

        // res.items = _.map(res.items, item => item = new WorkOrderFormQueryModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderTypeEntityModel().toModel(item));
    }

    async deploy(formId:string):Promise<any> {
        const url = `${URL_PATH}/synchronization/${formId}`;
        const res = await get(url);
        return res;
    }
}
export default new WorkOrderFormService();
